<template>
  <v-card>
    <v-subheader>
      {{ $vuetify.lang.t('$vuetify.sosAlarm.patientInfo') }}
    </v-subheader>
    <v-card-text>
      <v-row>
        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="5"
            >
              {{ $vuetify.lang.t('$vuetify.common.name') }}
            </v-col>

            <v-col
              cols="7"
              class="black--text"
            >
              {{ editItem.patientName }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="5"
            >
              {{ $vuetify.lang.t('$vuetify.common.gender') }}
            </v-col>

            <v-col
              cols="7"
              class="black--text"
            >
              {{ $vuetify.lang.t(`$vuetify.gender.${ editItem.gender.toLowerCase() }`) }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="5"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.ages') }}
            </v-col>

            <v-col
              cols="7"
              class="black--text"
            >
              {{ editItem.age }} {{ $vuetify.lang.t('$vuetify.sosAlarm.age') }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="5"
            >
              {{ $vuetify.lang.t('$vuetify.common.phone') }}
            </v-col>

            <v-col
              cols="7"
              class="black--text"
            >
              {{ editItem.patientPhone }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="6"
        >
          <v-row>
            <v-col
              cols="3"
            >
              {{ $vuetify.lang.t('$vuetify.common.identityCard') }}
            </v-col>

            <v-col
              cols="9"
              class="black--text"
            >
              {{ editItem.credentialNum }}
            </v-col>

          </v-row>
        </v-col>


        <v-col
          cols="6"
        >
          <v-row>
            <v-col
              cols="3"
            >
              {{ $vuetify.lang.t('$vuetify.common.address') }}
            </v-col>

            <v-col
              cols="9"
              class="black--text"
            >
              {{ editItem.mergerName }}
            </v-col>

          </v-row>
        </v-col>

      </v-row>
    </v-card-text>

    <v-divider class="my-4"></v-divider>

    <v-subheader>
      {{ $vuetify.lang.t('$vuetify.sosAlarm.callInfo') }}
    </v-subheader>
    <v-card-text>
      <v-row>
        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="5"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.status') }}
            </v-col>

            <v-col
              cols="7"
              class="black--text"
            >
              {{ $vuetify.lang.t(`$vuetify.sosAlarmStatus.${ editItem.status.toLowerCase() }`) }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="6"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.alarmTime') }}
            </v-col>

            <v-col
              cols="6"
              class="black--text"
            >
              {{ editItem.alarmTime | moment('YYYY-MM-DD HH:mm') }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="6"
        >
          <v-row>
            <v-col
              cols="3"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.address') }}
            </v-col>

            <v-col
              cols="9"
              class="black--text"
            >
              {{ editItem.address }}
            </v-col>

          </v-row>
        </v-col>

      </v-row>
    </v-card-text>

    <v-divider class="my-4"></v-divider>

    <v-subheader>
      {{ $vuetify.lang.t('$vuetify.sosAlarm.progressInfo') }}
    </v-subheader>
    <v-card-text
      v-if="currentSosAlarm"
    >
      <v-row>
        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="6"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.progressOperator') }}
            </v-col>

            <v-col
              cols="6"
              class="black--text"
            >
              {{ currentSosAlarm.operator}}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="6"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.progressTime') }}
            </v-col>

            <v-col
              cols="6"
              class="black--text"
            >
              {{ currentSosAlarm.progressTime | moment('YYYY-MM-DD HH:mm') }}
            </v-col>

          </v-row>
        </v-col>

        <v-col
          cols="3"
        >
          <v-row>
            <v-col
              cols="6"
            >
              {{ $vuetify.lang.t('$vuetify.sosAlarm.progressContent') }}
            </v-col>

            <v-col
              cols="6"
              class="black--text"
            >
              {{ currentSosAlarm.progressContent }}
            </v-col>

          </v-row>
        </v-col>

      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
        {{ $vuetify.lang.t('$vuetify.common.cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      editItem: {
        type: Object,
        default: () => null
      }
    },

    data () {
      return {
      }
    },

    computed: {

      ...mapGetters({
        currentSosAlarm: 'sosAlarm/currentSosAlarm'
      })
    },

    created () {
      this.sosAlarmShow(this.editItem.id)
    },

    methods: {

      ...mapActions({
        sosAlarmShow: 'sosAlarm/show'
      }),

      handleCancel () {
        this.$emit('cancel', true)
      }
    }
  }
</script>
